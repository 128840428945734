* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
}

body {
    font-family: sans-serif;
    font-size: 16px;
    padding: 50px;
}


button {
    padding: 10px 15px;
    outline: none;
    border: 1px solid #f0f0f0;
    background: #fafafa;
    border-radius: 6px;
    font-size: 18px;
}

button:hover {
    cursor: pointer;
}

input {
    border: 1px solid #f0f0f0;
    width: 100%;
    padding: 10px 15px;
    font-size: 18px;
    border-radius: 6px;
    outline: none;
}